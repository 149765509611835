button {
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.chart {
  width: 100%;
  height: 500px;
}

.datepicker-box {
  display: flex;
  padding: 0 80px;
  margin-top: 20px;
}

.datepicker-box__title {
  margin-right: 20px;
}

.custom-input {
  padding: 5px 10px;
  margin-right: 100px;
  background-color: #fff;
  border-radius: 5px;
  width: 219px;
  cursor: pointer;
}

.custom-input--submit {
  width: 60px;
  margin-right: 20px;
  color: #fff;
  background-color: #007bff;
}

.custom-input,
.datepicker-box__calendar {
  border: 1px solid #aaa;
}

.datepicker-box__calendar {
  background-color: #fff;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker-wrapper {
  width: auto;
}

.react-datepicker__month-text.react-datepicker__month--selected,
.react-datepicker__month-text.react-datepicker__month--in-range,
.react-datepicker__month-text.react-datepicker__month--selected:hover,
.react-datepicker__month-text.react-datepicker__month--in-range:hover {
  background-color: #007bff !important;
}
